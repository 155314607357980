* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  text-size-adjust: 100%;
  height: 100%;
}

body {
  height: 100%;
}

body,
body * {
  font-family: Graphik, sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  outline: 0 none;
  vertical-align: baseline;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;

  &:active,
  &:hover {
    outline: 0;
  }

  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

body {
  line-height: 1;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}

ol,
ul {
  list-style: none;
  margin-top: 0;
  margin-bottom: 10.5px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

b,
strong {
  font-weight: 700;
}

svg:not(:root) {
  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

button,
select {
  text-transform: none;
}

select[multiple],
select[size] {
  height: auto;
}

button {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 4px 0 0;
  line-height: normal;
}

input[type='range'] {
  display: block;
  width: 100%;
}

input,
textarea {
  border: none;
  box-shadow: none;
}

input,
textarea {
  color: #464545;
}

output {
  display: block;
  padding-top: 11px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #464545;
}

img {
  vertical-align: middle;
  border: 0;
}
